import React, { useState } from "react";
import Layout from "../../components/layout/Layout"
import Container from "../../components/layout/Container"
import Type from "../../components/typography/Type"
import { isLoggedIn } from "../../services/auth"
import axios from "axios";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import LoginPrompt from "../../components/app/loginPrompt"
import Input from "../../components/forms/Input"
import Select from "../../components/forms/Select"
import TextArea from "../../components/forms/TextArea"
import Hidden from "../../components/forms/Hidden"



const NewProject = () => {
  
const options = [

  { name: 'Projects', href: '/new/projects' },
  { name: 'Blueprints', href: '/new/blueprints/' },
  { name: 'Needs', href: '/new/needs/' },
  { name: 'Experience Steps', href: '/new/experiencesteps' },
  { name: 'Moments', href: '/new/moments' },
  { name: 'Capabilities', href: '/new/needs' },
  { name: 'Products', href: '/new/products' },
  { name: 'Value Streams', href: '/new/valuestreams' },
]
   
  return (
	<Layout>
	<Container>
	
	
	{isLoggedIn() ? (
	  <div>
	  
	  <Type type="h2">Where do you want to start?</Type>
	  <div className="grid grid-cols-3 gap-8 mb-16">
	  	{options.map((link) => (
	  	<a href={link.href}><div className="h-40 hover:bg-yellow flex items-center justify-center bg-yellow/25 rounded-lg  p-4">{link.name}</div></a>
	))}
	  </div>
	
			  

	  </div> 
   ) : (
	   <div>
		 <LoginPrompt/>
	   </div>     
	 )}



   </Container>
	</Layout>
  )
}

export default NewProject

export const Head = () => <title>Framework</title>

